import { handle } from 'redux-pack'
import { get, isEmpty, isNil, merge } from 'lodash'
import { decamelizeKeys } from 'humps'
import moment from 'constants/moment'
import * as A from 'constants/actions'
import T from 'types'
import {
  CHAT,
  PHONE,
  VISIO,
  COMPLEX,
  SIMPLE,
  CONSULT,
  MEDIUM,
  DEFAULT_PATHOLOGY,
} from 'constants/doctorSelectionStates'

type Action = any

type State = {
  loaded: boolean
  termsOfService: boolean
  survey: object
  surveyFetchedAt: Date | null
  surveyId: number | undefined
  surveyUuid: number | undefined
  questionName: string | null
  doctors: T.Doctor[]
  patientAttributes: object
  medium: string | null
  consultationType: string | null
  consultationSpecialty: string | null
  skipDoctorSelection: boolean
  socialSecurityMode: boolean
  invitationToken: string | null
  uploadingSurveyStartedAt: Date | null
  invitationAcceptedAt: Date | null
  loading: boolean | null
  success: boolean | null
  error: string | null,
}

const initialState: State = {
  loaded: false,
  termsOfService: false,
  survey: {},
  surveyFetchedAt: null,
  surveyId: undefined,
  surveyUuid: undefined,
  questionName: null,
  doctors: [],
  patientAttributes: {},
  medium: null,
  consultationType: SIMPLE,
  consultationSpecialty: DEFAULT_PATHOLOGY,
  skipDoctorSelection: false,
  socialSecurityMode: false,
  invitationToken: null,
  uploadingSurveyStartedAt: null,
  invitationAcceptedAt: null,
  loading: null,
  success: null,
  error: null,
}

export default (state = initialState, action: Action) => {
  const { payload } = action
  switch (action.type) {
    case A.SEND_CONSULTATION_PAYMENT:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          returnFromThreeDSecure: false,
        })
      })
    case A.SET_SURVEY:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loaded: false,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({
          ...prevState,
          loading: false,
          loaded: true,
        }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => {
          const isRecovery = !isNil(payload.isRecovery) && payload.isRecovery
          const returnFromThreeDSecure =
            !isNil(payload.returnFromThreeDSecure) && payload.returnFromThreeDSecure

          const returningData =
            isRecovery || returnFromThreeDSecure
              ? {
                patientAttributes: payload.patientAttributes,
                orderNumber: payload.orderNumber
              }
              : {}
          return {
            ...prevState,
            isRecovery,
            returnFromThreeDSecure,
            questionName: null,
            survey: {
              ...decamelizeKeys(payload.survey),
              // do not decamelize `pages`
              pages: { ...{ survey: {} }, ...payload }.survey.pages,
              // neither `completedHtml`
              completedHtml: { ...{ survey: {} }, ...payload }.survey.completedHtml,
            },
            surveyId: payload.id,
            surveyUuid: payload.surveyUuid,
            consultationSpecialty: payload.consultationSpecialty.slug,
            surveyFetchedAt: moment().toDate(),
            success: true,
            ...returningData,
          }
        },
      })
    case A.GET_SURVEY_AND_SUBMISSION:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState }),
        success: prevState => {
          const previousSubmission = payload.lastSubmission
          const hasPreviousSubmission = !isNil(previousSubmission)
          const survey = hasPreviousSubmission ? previousSubmission : payload.survey
          // TODO Lucas - check if need to decamelize shit like above
          return {
            ...prevState,
            survey,
            surveyId: payload.id,
            hasPreviousSubmission
          }
        }
      })
    case A.SET_SURVEY_OBJECT:
      return {
        ...state,
        survey: payload,
      }
    case A.SET_SURVEY_QUESTION_NAME:
      return {
        ...state,
        questionName: payload,
      }
    case A.SET_DOCTORS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false, loaded: !isEmpty(prevState.survey) }),
        failure: prevState => ({ ...prevState, error: payload, success: false }),
        success: prevState => ({
          ...prevState,
          doctors: payload.doctors,
          success: true,
        }),
      })
    case A.SET_PERSONAL_DATA:
      return {
        ...state,
        patientAttributes: merge(state.patientAttributes, payload),
      }
    case A.CREATE_PATIENT:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          patientAttributes: payload,
        }),
      })
    case A.UPDATE_PATIENT_DATA:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          patientAttributes: payload.data,
        }),
      })
    case A.UPLOAD_SURVEY_OBJECT:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          uploadingSurveyStartedAt: moment().toDate(),
        }),
        success: prevState => ({
          ...prevState,
          invitationToken: payload.data.spreeUser.invitationToken,
          invitationAcceptedAt: payload.data.spreeUser.invitationAcceptedAt,
        }),
      })
    case A.SET_TERMS_OPTIN:
      return {
        ...state,
        termsOfService: true,
      }
    case A.SET_SOCIAL_SECURITY_MODE:
      return {
        ...state,
        socialSecurityMode: payload,
      }
    case A.SET_CHOSEN_MEDIUM:
      return {
        ...state,
        medium: payload,
      }
    case A.SET_CHOSEN_CONSULTATION_TYPE:
      return {
        ...state,
        consultationType: payload,
      }
    case A.SET_FOCUSED_SPECIALTY:
      return {
        ...state,
        consultationSpecialty: payload,
      }
    case A.UPDATE_FOCUSED_CONSULTATION:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          consultationType: get(payload.consultation, 'consultationType'),
          medium: get(payload.consultation, 'communicationChannel'),
        }),
      })
    case A.SET_OPEN_CONSULTATION_DOCTOR:
      return {
        ...state,
        skipDoctorSelection: true,
        consultationType: COMPLEX
      }
    case A.RESET_OPEN_CONSULTATION_DOCTOR:
      return {
        ...state,
        skipDoctorSelection: false,
        consultationType: SIMPLE
      }
    case A.END_3DS_CHALLENGE:
      return {
        ...state,
        returnFromThreeDSecure: payload
      }
    default:
      return state
  }
}
