import * as A from 'constants/actions'
import { showNotification } from './ux'
import store from '../store'
import { ApiService } from 'services'
import { I18n } from 'react-redux-i18n'
import T from 'types'
import moment from 'moment'

export const getSurvey = (params: T.OnboardingParams) => {
  return {
    meta: {
      onSuccess: (response: any) => {
        // TODO FUNNEL Handle case where all is already done
        if (response.order && response.order.state === 'complete') {
          if (response.isRecovery) {
            store.dispatch(showNotification('info', I18n.t('onboarding.recoveryNotification')))
          } else if (response.returnFromThreeDSecure) {
            store.dispatch(showNotification('success', 'Votre paiement a bien été validé'))
          }
        } else if (response.consultation) {
          store.dispatch(showNotification('error', 'Une erreur est survenue et le paiement a échoué'))
        } else if (response.isRecovery) {
          store.dispatch(showNotification('info', 'Vos informations ont été récupérées'))
        }
      },
    },
    type: A.SET_SURVEY,
    promise: ApiService.getSurvey(params),
  }
}

export const getSurveyAndSubmission = (consultSpecialty: string, onSuccess: () => void) => ({
  meta: {
    onSuccess: () => {
      onSuccess()
    },
    onFailure: () => {
      store.dispatch(showNotification('error', 'Une erreur est survenue'))
    },
  },
  type: A.GET_SURVEY_AND_SUBMISSION,
  promise: ApiService.getSurveyAndSubmission(consultSpecialty).then(({ data }) => data),
})

export const setSurveyObject = (survey: object) => ({
  type: A.SET_SURVEY_OBJECT,
  payload: survey,
})

export const setSurveyQuestionName = (questionName: string) => ({
  type: A.SET_SURVEY_QUESTION_NAME,
  payload: questionName,
})

export const setPersonalData = (data: object) => ({
  type: A.SET_PERSONAL_DATA,
  payload: data,
})

export const createPatient = (data: any, source: string) => ({
  meta: {
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Une erreur est survenue appelez le support technique'),
      )
    },
  },
  promise: ApiService.createPatient(data, source),
  type: A.CREATE_PATIENT,
})


export const setTermsOptin = () => ({
  type: A.SET_TERMS_OPTIN,
})

export const setSocialSecurityMode = (socialSecurityMode?: boolean) => ({
  type: A.SET_SOCIAL_SECURITY_MODE,
  payload: socialSecurityMode
})

export const setChosenMedium = (medium: string) => ({
  type: A.SET_CHOSEN_MEDIUM,
  payload: medium,
})

export const setChosenConsultType = (consultationType: string) => ({
  type: A.SET_CHOSEN_CONSULTATION_TYPE,
  payload: consultationType,
})

export const updatePatientData = (data: any) => ({
  meta: {
    onSuccess: () => {
      store.dispatch(showNotification('success', 'Vos informations ont bien été sauvegardées'))
    },
    onFailure: () => {
      store.dispatch(showNotification('error', 'Une erreur est survenue'))
    },
  },
  promise: ApiService.updatePatientData(data),
  type: A.UPDATE_PATIENT_DATA,
})

export const setFocusedSpecialty = (specialty: string) => ({
  type: A.SET_FOCUSED_SPECIALTY,
  payload: specialty,
})

export const uploadSurvey = (orderNumber: string | undefined, survey: object) => {
  const startedAt = store.getState().onboarding.uploadingSurveyStartedAt
  const diff = moment().diff(moment(startedAt), 's')
  const shouldUpload = !startedAt || diff > 2

  if (shouldUpload) {
    return {
      meta: {
        onSuccess: () => {
          store.dispatch(showNotification('success', 'Vos réponses ont bien été enregistrées'))
        },
        onFailure:
          () => {
            store.dispatch(
              showNotification('error', 'Une erreur est survenue appelez le support technique'),
            )
          },
      },
      promise: ApiService.uploadSurvey(orderNumber, survey),
      type: A.UPLOAD_SURVEY_OBJECT,
    }
  }
}

export const acceptInvitation = (data: any, onSuccess: () => void) => ({
  meta: {
    onSuccess: () => {
      store.dispatch(showNotification('success', 'Votre compte a bien été créé. Vous allez être redirigé vers la page de connexion à votre espace patient.'))
      onSuccess()
    },
    onFailure: () => {
      store.dispatch(
        showNotification('error', 'Une erreur est survenue appelez le support technique'),
      )
    },
  },
  promise: ApiService.acceptInvitation(data),
  type: A.ACCEPT_INVITATION,
})

